import React from "react";

const NotFoundPage = () => {
  return (
    <div>
      <h3> 404 Not Found</h3>
    </div>
  );
};

export default NotFoundPage;
