import React from "react";

const RecipePage = () => {
  return (
    <div>
      <h3> Recipe </h3>
    </div>
  );
};

export default RecipePage;
